import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { UserInfoDto } from "@eon-one/one-ui";
import { Observable } from "rxjs";

import { EnvironmentService } from "./environment.service";
@Injectable({
  providedIn: "root",
})
export class UserService {
  readonly #http = inject(HttpClient);
  readonly #environmentService = inject(EnvironmentService);

  public fetchUserDetails(
    expands: string[] = [
      "productInstances",
      "products",
      "productSubscriptions",
    ],
  ): Observable<UserInfoDto> {
    return this.#http.get<UserInfoDto>(
      encodeURI(
        `${this.#environmentService.servicePortalApiUrl}/iam/users/self/info?expand=${expands.join(",")}`,
      ),
    );
  }
}
