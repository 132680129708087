import { StatusType } from "@app/shared";
import {
  ConnectionRequestDto,
  ContactDetailsDto,
  CustomerConfigurationDto,
  FlowType,
  FormModule,
  FormRequestType,
  LocationDto,
} from "src/api/dso-portal/generated/models";

const flowTypeMapping = new Map<ExtendedFormModule, FlowType[]>([
  [
    "ELECTRICITY",
    [
      "GRID_CONNECTION",
      "PV_2",
      "BALCONY_PV",
      "CHARGING_DEVICE_2",
      "HEAT_PUMP_2",
      "STORAGE_2",
      "CONSTRUCTION_ELECTRICITY",
      "OTHER_2",
    ],
  ],
  ["GAS", ["GRID_CONNECTION"]],
  ["WATER", ["GRID_CONNECTION", "SEWAGE", "CONSTRUCTION_WATER"]],
  ["HEAT", ["GRID_CONNECTION"]],
  ["ELECTRICITY_COMMISSIONING", ["MAIN_POWER_SUPPLY", "CUSTOMER_FACILITY"]],
]);

export interface RequestTableData {
  id?: string;
  module: FormModule;
  requestType: FormRequestType;
  requestId: string;
  flowType: FlowType;
  location: LocationDto;
  requesterContact: ContactDetailsDto;
  createdAt: string;
  status: StatusType;
}

export type ExtendedFormModule = FormModule | CommissioningFormModule;

export type CommissioningFormModule = `${FormModule}_COMMISSIONING`;

export class ModuleHelper {
  static mapModules(
    configuration: CustomerConfigurationDto,
  ): Array<ExtendedFormModule> {
    const registrationModules: ExtendedFormModule[] =
      configuration.moduleSettings.map(
        (moduleSettings) => moduleSettings.module,
      );
    const commissioningModules: ExtendedFormModule[] =
      configuration.commissioningModules.map(
        (module) => `${module}_COMMISSIONING` as ExtendedFormModule,
      );
    return registrationModules.concat(commissioningModules);
  }

  static mapFlowTypes(selectedModules: ExtendedFormModule[]): FlowType[] {
    let availableFlowTypes: FlowType[] = [];
    selectedModules.forEach((module) => {
      availableFlowTypes = availableFlowTypes.concat(
        flowTypeMapping.get(module)!,
      );
    });
    return [...new Set(availableFlowTypes)];
  }

  static mapRequestList(
    requestList: ConnectionRequestDto[],
  ): RequestTableData[] {
    return requestList.map((request) => ({
      id: request.id,
      module: request.module,
      requestId: request.requestId,
      requestType: request.requestType,
      flowType: request.flowType,
      location: request.location,
      requesterContact: request.requesterContact,
      createdAt: request.createdAt,
      status: request.currentStatus.status,
    }));
  }

  static isRegistrationModule(
    module: ExtendedFormModule,
  ): module is FormModule {
    return (
      module === "ELECTRICITY" ||
      module === "GAS" ||
      module === "WATER" ||
      module === "HEAT"
    );
  }
  static isCommissioningModule(
    module: ExtendedFormModule,
  ): module is CommissioningFormModule {
    return (
      module === "ELECTRICITY_COMMISSIONING" ||
      module === "GAS_COMMISSIONING" ||
      module === "WATER_COMMISSIONING" ||
      module === "HEAT_COMMISSIONING"
    );
  }

  static toFormRequestType(
    module: ExtendedFormModule | null | undefined,
  ): FormRequestType | null {
    if (!module) {
      return null;
    }
    if (ModuleHelper.isRegistrationModule(module)) {
      return "REGISTRATION";
    } else if (ModuleHelper.isCommissioningModule(module)) {
      return "COMMISSIONING";
    } else {
      return null;
    }
  }

  static toFormModule(module?: ExtendedFormModule | null): FormModule | null {
    if (!module) {
      return null;
    }
    if (module.endsWith("_COMMISSIONING")) {
      return module.split("_")[0] as FormModule;
    } else {
      return module as FormModule;
    }
  }

  static toExtendedFormModule(
    module: FormModule | null,
    requestType: FormRequestType | null,
  ): ExtendedFormModule | null {
    if (!module) {
      return null;
    }

    switch (requestType) {
      case "COMMISSIONING":
        return (module + "_COMMISSIONING") as ExtendedFormModule;
      case "REGISTRATION":
        return module;
      default:
        return null;
    }
  }
}
