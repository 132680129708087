import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { forkJoin, Observable, ReplaySubject, take } from "rxjs";

export enum CUSTOM_APP_ICON {
  SAVE = "save",
  ARROW_RIGHT = "arrow_right",
  DOWNLOAD = "download",
  SADE_FACE_EMOJI = "sad_face_emoji",
  MEDIUM_SADE_FACE_EMOJI = "medium_sad_face_emoji",
  MODULE_GAS = "module_gas",
  MODULE_HEAT = "module_heat",
  MODULE_WATER = "module_water",
  MODULE_ELECTRICITY = "module_electricity",
  MODULE_ELECTRICITY_COMMISSIONING = "module_electricity_commissioning",
  PLUS = "plus",
  TIME = "time",
  HISTORY = "history",
  CHECK_MINUS = "check_minus",
  CHECK_BOX = "check_box",
  INFO = "info",
  FILE = "file",
  CHEVRON_LEFT = "chevron_left",
  ARCHIVE = "archive",
  EXCLAMATION_MARK = "exclamation_mark",
  CLOSE = "close",
  FILTER_RESET = "filter_reset",
  STATUS_CHANGE_INFORMED = "status_change_informed",
  STATUS_CHANGE_NOT_INFORMED = "status_change_not_informed",
}

@Injectable({
  providedIn: "root",
})
export class IconInitializerService {
  private _configured$: ReplaySubject<void> = new ReplaySubject(1);
  private loadedIcons$: Observable<SVGElement>[] = [];
  private readonly customIcons = Object.values(CUSTOM_APP_ICON);

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.load(this.customIcons);
  }

  public get configured$(): Observable<void> {
    return this._configured$.asObservable();
  }

  private load(customIcons: string[]): void {
    if (customIcons.length === 0) {
      this._configured$.next();
      this._configured$.complete();
    } else {
      customIcons.forEach((icon: string) => {
        const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${icon}.svg`,
        );
        this.addIcon(icon, safeUrl);
        this.addIconForPreloading(safeUrl);
      });
      forkJoin(this.loadedIcons$)
        .pipe(take(1))
        .subscribe(() => {
          this._configured$.next();
          this._configured$.complete();
        });
    }
  }

  private addIcon(icon: string, url: SafeUrl): void {
    this.matIconRegistry.addSvgIcon(icon, url);
  }

  private addIconForPreloading(url: SafeUrl): void {
    const svgIconFromUrl = this.matIconRegistry.getSvgIconFromUrl(url);
    this.loadedIcons$.push(svgIconFromUrl);
  }
}
