import { inject, Injectable } from "@angular/core";
import { UserInfoDto } from "@eon-one/one-ui";
import { Observable, shareReplay } from "rxjs";

import { UserService } from "@app/core/services/user.service";

@Injectable({
  providedIn: "root",
})
export class SelfService {
  #cachedSelfData$?: Observable<UserInfoDto>;
  readonly #userService: UserService = inject(UserService);

  public getSelf(): Observable<UserInfoDto> {
    if (!this.#cachedSelfData$) {
      this.#cachedSelfData$ = this.#userService
        .fetchUserDetails()
        .pipe(shareReplay(1));
    }
    return this.#cachedSelfData$;
  }
}
