import { MatDialogConfig } from "@angular/material/dialog";

export class DialogConfigFactory {
  private static readonly DEFAULT_DISABLE_CLOSE = true;
  private static readonly AUTO_FOCUS = false;
  private static readonly WIDTH = "776px";

  public static build(
    width = DialogConfigFactory.WIDTH,
    data?: unknown,
    disableClose = DialogConfigFactory.DEFAULT_DISABLE_CLOSE,
    autoFocus = DialogConfigFactory.AUTO_FOCUS,
  ): MatDialogConfig {
    return {
      width,
      disableClose,
      autoFocus,
      data,
    };
  }
}
