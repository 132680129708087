import { inject, Injectable } from "@angular/core";
import { Observable, shareReplay } from "rxjs";

import { CustomerConfigurationDto } from "src/api/dso-portal/generated/models";
import { CustomerConfigurationService } from "src/api/dso-portal/generated/services";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  cachedConfigurationData$?: Observable<CustomerConfigurationDto>;
  readonly #customerConfigurationService: CustomerConfigurationService = inject(
    CustomerConfigurationService,
  );

  public getConfiguration(): Observable<CustomerConfigurationDto> {
    if (!this.cachedConfigurationData$) {
      this.cachedConfigurationData$ = this.#customerConfigurationService
        .getConfiguration()
        .pipe(shareReplay(1));
    }
    return this.cachedConfigurationData$;
  }
}
