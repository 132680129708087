import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, filter, take } from "rxjs";

@Injectable()
export class MultiLanguageTitleStrategy extends TitleStrategy {
  readonly #destroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);
  readonly #title = inject(Title);
  #currentTitleKey$ = new BehaviorSubject<string | undefined>(undefined);

  constructor() {
    super();
    combineLatest({
      titleKey: this.#currentTitleKey$,
      languageChange: this.#translateService.onLangChange,
    })
      .pipe(
        filter((value) => this.#isWithoutUndefinedValues(value)),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe(({ titleKey }) => {
        if (titleKey) {
          this.#readTranslation(titleKey);
        }
      });
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const titleKey = this.buildTitle(snapshot);
    if (titleKey) {
      this.#currentTitleKey$.next(titleKey);
    }
  }

  #isWithoutUndefinedValues(value: {
    titleKey: string | undefined;
    languageChange: LangChangeEvent;
  }): boolean {
    const { titleKey, languageChange } = value;
    return titleKey !== undefined && languageChange.lang !== undefined;
  }

  #readTranslation(titleKey: string): void {
    this.#translateService
      .get(titleKey)
      .pipe(take(1))
      .subscribe((translatedTitle: string) => {
        this.#title.setTitle(translatedTitle);
      });
  }
}
