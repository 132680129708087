import {
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

export class SnackBarConfigFactory {
  private static readonly DEFAULT_DURATION = 4000;
  private static readonly DEFAULT_HORIZONTAL_POSITION = "end";
  private static readonly DEFAULT_VERTICAL_POSITION = "bottom";

  public static build(
    panelClass?: string[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    duration: number = SnackBarConfigFactory.DEFAULT_DURATION,
    horizontalPosition: MatSnackBarHorizontalPosition = SnackBarConfigFactory.DEFAULT_HORIZONTAL_POSITION,
    verticalPosition: MatSnackBarVerticalPosition = SnackBarConfigFactory.DEFAULT_VERTICAL_POSITION,
  ): MatSnackBarConfig {
    return {
      duration,
      horizontalPosition,
      verticalPosition,
      panelClass,
      data,
    };
  }
}
