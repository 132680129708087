<!--TODO: replace one-pt-1 etc. with one-p-1 once fixed in one-ui-->
<div class="error-card-content one-pt-1 one-pr-1 one-pb-1 one-pl-1">
  <mat-icon
    [attr.aria-label]="iconAltKey | translate"
    [svgIcon]="icon"
    class="error-card-content-icon"
  />
  <h1>{{ titleKey | translate }}</h1>
  <ng-content />
</div>
