import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { EnvironmentService } from "./environment.service";

export const additionalDocumentsPath: string = "additional-documents";
@Injectable({
  providedIn: "root",
})
export class AdditionalDocumentService {
  readonly #httpClient = inject(HttpClient);
  readonly #environmentService = inject(EnvironmentService);

  public uploadDocument(
    connectionRequestId: string,
    file: File,
  ): Observable<number> {
    const formData = new FormData();
    formData.append("file", file);
    const uploadUrl = `${this.#environmentService.apiUrl}/connection-requests/${connectionRequestId}/${additionalDocumentsPath}`;

    return this.#httpClient
      .post(uploadUrl, formData, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(map((event) => this.#getUploadProgress(event)));
  }

  #getUploadProgress(event: HttpEvent<object>): number {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        if (event.total) {
          return Math.round(100 * (event.loaded / event.total));
        }
        break;
      case HttpEventType.Response:
        return 100;
    }
    return 0;
  }
}
