import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private readonly authService: AuthService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  public logout(): Observable<void> {
    return this.authService.logout({
      logoutParams: { returnTo: this.document.location.origin },
    });
  }
}
