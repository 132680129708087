import { inject, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { ResultSnackBarComponent } from "@app/shared";

import { SnackBarConfigFactory } from "../utils";

export type NotificationType = "SUCCESS" | "ERROR";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  readonly #snackBar = inject(MatSnackBar);
  readonly #panelClassesByType = new Map<NotificationType, string[]>([
    ["SUCCESS", ["snack-bar-success"]],
    ["ERROR", ["snack-bar-error"]],
  ]);

  public notify(type: NotificationType, message?: string): void {
    const panelClasses = this.#panelClassesByType.get(type);
    if (!panelClasses) {
      throw new Error(`Unsupported Notification Type ${type}`);
    }
    if (message) {
      this.#snackBar.open(
        message,
        "X",
        SnackBarConfigFactory.build(["snack-bar-error"], "ERROR"),
      );
    } else {
      this.#snackBar.openFromComponent(
        ResultSnackBarComponent,
        SnackBarConfigFactory.build(panelClasses, type),
      );
    }
  }
}
