import { Pipe, PipeTransform } from "@angular/core";

import { LocationDto } from "src/api/dso-portal/generated/models";

@Pipe({
  name: "address",
  standalone: true,
})
export class AddressPipe implements PipeTransform {
  transform({ address, district, parcel, parcelNumber }: LocationDto): string {
    const { street, streetNumber, zipCode, city } = address;
    let mappedAddress: string;
    if (district && parcel && parcelNumber) {
      let streetInfo: string;
      if (street || streetNumber) {
        if (street && streetNumber) {
          streetInfo = `${street} ${streetNumber}`;
        } else {
          streetInfo = `${street ?? streetNumber}`;
        }
        mappedAddress = `${streetInfo}, ${district} ${parcel} ${parcelNumber}, ${zipCode} ${city}`;
      } else {
        mappedAddress = `${district} ${parcel} ${parcelNumber}, ${zipCode} ${city}`;
      }
    } else {
      mappedAddress = `${street} ${streetNumber}, ${zipCode} ${city}`;
    }
    return mappedAddress;
  }
}
