import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class LoadingService {
  readonly isLoading: Signal<boolean> = computed(
    () => this.#loadingCounter() !== 0,
  );
  readonly #loadingCounter: WritableSignal<number> = signal(0);

  public increaseCounter(): void {
    this.#loadingCounter.update((loadingCounter) => loadingCounter + 1);
  }

  public decreaseCounter(): void {
    this.#loadingCounter.update((loadingCounter) => loadingCounter - 1);
  }
}
