import {
  APP_LANGUAGE,
  DEFAULT_APP_LANGUAGE,
  LanguageKey,
  LanguageLocale,
} from "./app.constants";

/**
 * Returns the language key based on the language locale or culture.
 * @param value Language locale, e.g. 'en-GB' or 'en'
 * @returns Language key, e.g. 'en' or 'de'
 */
export const getLanguageLocale = (value: string): LanguageKey => {
  const lang = getLanguageDetails(value);
  return lang.locale;
};

/**
 * Returns the language locale based on the language key or culture.
 * @param value Language locale, e.g. 'en-GB' or 'en'
 * @returns Language locale, e.g. 'en-GB' or 'de'
 */
export const getLanguageLocaleCulture = (value: string): string => {
  const lang = getLanguageDetails(value);
  return lang.localeCulture ?? lang.locale;
};

const getLanguageDetails = (value: string): LanguageLocale => {
  return (
    Object.values(APP_LANGUAGE).find(
      (language: LanguageLocale) =>
        language.localeCulture === value || language.locale === value,
    ) ?? DEFAULT_APP_LANGUAGE
  );
};
