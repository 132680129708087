import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  #pageLabel: string = "";
  #ofLabel: string = "";
  constructor(private translate: TranslateService) {
    super();

    this.getAndInitTranslations();
    this.translate.onLangChange.subscribe(() => this.getAndInitTranslations());
  }

  public getAndInitTranslations(): void {
    this.translate
      .get([
        "PAGINATOR.ITEMS_PER_PAGE",
        "PAGINATOR.FIRST_PAGE",
        "PAGINATOR.PREVIOUS_PAGE",
        "PAGINATOR.NEXT_PAGE",
        "PAGINATOR.LAST_PAGE",
        "PAGINATOR.PAGE_LABEL",
        "PAGINATOR.OF_LABEL",
      ])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation["PAGINATOR.ITEMS_PER_PAGE"];
        this.firstPageLabel = translation["PAGINATOR.FIRST_PAGE"];
        this.lastPageLabel = translation["PAGINATOR.LAST_PAGE"];
        this.nextPageLabel = translation["PAGINATOR.NEXT_PAGE"];
        this.previousPageLabel = translation["PAGINATOR.PREVIOUS_PAGE"];
        this.#pageLabel = translation["PAGINATOR.PAGE_LABEL"];
        this.#ofLabel = translation["PAGINATOR.OF_LABEL"];
        this.changes.next();
      });
  }

  public override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number,
  ): string => {
    const currentPage = length > 0 ? page + 1 : 0;
    const totalPages = Math.ceil(length / pageSize);
    return `${this.#pageLabel} ${currentPage} ${this.#ofLabel} ${totalPages}`;
  };
}
