import { Component, Input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "dso-error-card",
  standalone: true,
  imports: [TranslateModule, MatIcon],
  templateUrl: "./error-card.component.html",
  styleUrl: "./error-card.component.scss",
})
export class ErrorCardComponent {
  @Input()
  public icon: string = "sad_face_emoji";
  @Input()
  public iconAltKey: string = "ERROR.ICON_ALT_TEXT";
  @Input()
  public titleKey: string = "";
}
