import {
  FormModule,
  FormRequestType,
} from "src/api/dso-portal/generated/models";

export abstract class ModuleBase {
  public isRequestWithTypeCommissioning(
    requestType?: FormRequestType,
  ): boolean {
    return requestType ? requestType === "COMMISSIONING" : false;
  }

  public throwUnsupportedError(module: FormModule): void {
    throw new Error(`Not supported Module: ${module}`);
  }
}
