import { CUSTOM_APP_ICON } from "@app/core/services";
import { StatusType } from "@app/shared";

import { StatusMeta } from "../ui/status-indicator/status";

export class StatusMetaProvider {
  private static readonly META_BY_STATUS: Map<StatusType, StatusMeta> = new Map(
    [
      [
        "NEW",
        {
          status: "NEW",
          translationIdentifier: "REQUESTS_DETAILS.STATUS_NEW",
          altTextTranslationIdentifier:
            "REQUESTS_DETAILS.STATUS_NEW_ICON_ALT_TEXT",
          icon: CUSTOM_APP_ICON.PLUS,
        },
      ],
      [
        "PROGRESS",
        {
          status: "PROGRESS",
          translationIdentifier: "REQUESTS_DETAILS.STATUS_PROGRESS",
          altTextTranslationIdentifier:
            "REQUESTS_DETAILS.STATUS_PROGRESS_ICON_ALT_TEXT",
          icon: CUSTOM_APP_ICON.TIME,
        },
      ],
      [
        "PENDING",
        {
          status: "PENDING",
          translationIdentifier: "REQUESTS_DETAILS.STATUS_PENDING",
          altTextTranslationIdentifier:
            "REQUESTS_DETAILS.STATUS_PENDING_ICON_ALT_TEXT",
          icon: CUSTOM_APP_ICON.HISTORY,
        },
      ],
      [
        "REJECTED",
        {
          status: "REJECTED",
          translationIdentifier: "REQUESTS_DETAILS.STATUS_REJECTED",
          altTextTranslationIdentifier:
            "REQUESTS_DETAILS.STATUS_REJECTED_ICON_ALT_TEXT",
          icon: CUSTOM_APP_ICON.CHECK_MINUS,
        },
      ],
      [
        "DONE",
        {
          status: "DONE",
          translationIdentifier: "REQUESTS_DETAILS.STATUS_DONE",
          altTextTranslationIdentifier:
            "REQUESTS_DETAILS.STATUS_DONE_ICON_ALT_TEXT",
          icon: CUSTOM_APP_ICON.CHECK_BOX,
        },
      ],
      [
        "ARCHIVED",
        {
          status: "ARCHIVED",
          translationIdentifier: "REQUESTS_DETAILS.STATUS_ARCHIVED",
          altTextTranslationIdentifier:
            "REQUESTS_DETAILS.STATUS_ARCHIVED_ICON_ALT_TEXT",
          icon: CUSTOM_APP_ICON.ARCHIVE,
        },
      ],
    ],
  );

  public static provide(status: StatusType): StatusMeta {
    const meta = StatusMetaProvider.META_BY_STATUS.get(status);
    if (meta) {
      return meta;
    }
    throw new Error(`Unsupported status: ${status}`);
  }

  public static provideAll(): StatusMeta[] {
    return Array.from(StatusMetaProvider.META_BY_STATUS.values());
  }
}
