import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { tap } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";

import { ProductSubscriptionDto, SelfService } from "@app/core/services";
import { GLOBAL_ERROR_TYPE } from "@app/shared";

export const addonCheckGuardFn: () => Observable<boolean> = () => {
  const selfService = inject(SelfService);
  const router = inject(Router);

  return selfService.getSelf().pipe(
    map(
      (user) =>
        !!user.productSubscriptions?.some(
          (subscription) =>
            !!(subscription as ProductSubscriptionDto).addons?.some(
              (addon) => addon.key === "request-management",
            ),
        ),
    ),
    tap((hasAddon) => {
      if (!hasAddon) {
        router.navigate(["/error"], {
          state: { errorType: GLOBAL_ERROR_TYPE.ADDON_NOT_ENABLED },
          skipLocationChange: true,
        });
      }
    }),
  );
};
