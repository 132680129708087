import { HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { finalize } from "rxjs";

import { environment } from "@environments/index";

import { additionalDocumentsPath, LoadingService } from "../services";

const endpointsRequiringLoadingIndicator = [
  environment.apiUrl,
  environment.servicePortalApiUrl,
];

// hide global loader for upload document as it has it's own loader
function isDocumentUploadRequest(req: HttpRequest<unknown>): boolean {
  const isAdditionalDocumentEndpoint = req?.url?.includes(
    additionalDocumentsPath,
  );
  const isPostRequest = req.method === "POST";
  return isAdditionalDocumentEndpoint && isPostRequest;
}

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const isLoadingIndicatorRequired =
    !isDocumentUploadRequest(req) &&
    endpointsRequiringLoadingIndicator.some((endpoint) =>
      req?.url?.includes(endpoint),
    );
  if (isLoadingIndicatorRequired) {
    const loadingService = inject(LoadingService);
    loadingService.increaseCounter();
    return next(req).pipe(
      finalize(() => {
        loadingService.decreaseCounter();
      }),
    );
  } else {
    return next(req);
  }
};
