<button
  (click)="emitClick()"
  [attr.aria-label]="areaLabelButton()"
  [matTooltip]="tooltip()"
  [disabled]="disabled()"
  mat-mini-fab
  class="reset-filter-button"
>
  <mat-icon
    [attr.aria-label]="ariaLabelIcon()"
    svgIcon="filter_reset"
    aria-hidden="false"
  />
</button>
