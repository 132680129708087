import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { InterpolationParameters, TranslateService } from "@ngx-translate/core";
import { EMPTY, Observable, take } from "rxjs";

import { GLOBAL_ERROR_TYPE } from "@app/shared";

import { NotificationService } from "./notification.service";

export interface ErrorHandlingConfig {
  shouldRedirect: boolean;
  showErrorSnackbar?: boolean;
  msgTranslationIdentifier?: string;
  translationInterpolateParams?: InterpolationParameters;
}
@Injectable({
  providedIn: "root",
})
export class ErrorHandlingService implements ErrorHandler {
  readonly #translateService = inject(TranslateService);
  readonly #router = inject(Router);
  readonly #notificationService = inject(NotificationService);

  public handleError(
    error: HttpErrorResponse | Error,
    config: ErrorHandlingConfig = {
      shouldRedirect: true,
    },
  ): Observable<never> {
    if (this.#isNetworkError(error)) {
      this.#navigateToUnexpectedErrorPage();
      return EMPTY;
    }
    let showErrorOverride = config.showErrorSnackbar;
    if (error instanceof HttpErrorResponse && config.shouldRedirect) {
      this.#logHttpErrorToConsole(error);
      showErrorOverride = false;
      switch (error.status) {
        case 401:
        case 403:
          this.#router.navigate(["/error"], {
            state: {
              errorType: GLOBAL_ERROR_TYPE.UNAUTHORIZED,
            },
            skipLocationChange: true,
          });
          break;
        case 400:
        case 404:
          this.#router.navigate(["/404"]);
          break;
        default:
          this.#navigateToUnexpectedErrorPage();
      }
    } else {
      // eslint-disable-next-line no-console
      console.error("An error occurred:", error);
    }
    if (showErrorOverride) {
      this.#populateError(
        config.msgTranslationIdentifier,
        config.translationInterpolateParams,
      );
    }
    return EMPTY;
  }

  #isNetworkError(error: HttpErrorResponse | Error): boolean {
    return error instanceof HttpErrorResponse && error.status === 0;
  }

  #navigateToUnexpectedErrorPage(): void {
    this.#router.navigate(["/error"], {
      state: {
        errorType: GLOBAL_ERROR_TYPE.UNEXPECTED,
      },
      skipLocationChange: true,
    });
  }

  #logHttpErrorToConsole(error: HttpErrorResponse): void {
    const errorCode = error.status;
    const errorMessage = error.message;
    // eslint-disable-next-line no-console
    console.error(
      `Backend returned code ${errorCode}, body was ${errorMessage}`,
    );
  }

  #populateError(
    message?: string,
    interpolateParams?: InterpolationParameters,
  ): void {
    this.#translateService
      .get(message ?? "COMMON.GENERIC_ERROR", interpolateParams)
      .pipe(take(1))
      .subscribe((message: string) =>
        this.#notificationService.notify("ERROR", message),
      );
  }
}
