import { NavigationItem } from "@eon-one/one-ui";

import { SideNavigationLink } from "@app/index";

export class NavigationItemMapper {
  static map(item: SideNavigationLink): NavigationItem {
    return {
      title: item.translationKey,
      route: item.route,
      appearOnlyOn: ["mobile"],
    };
  }
}
