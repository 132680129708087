export const PRODUCT_NAME = "OnePortal";

export type LanguageKey = "en" | "de";

export type LanguageLocale = {
  label: string;
  locale: LanguageKey;
  localeCulture?: string;
};

export const APP_LANGUAGE: Record<LanguageKey, LanguageLocale> = {
  en: {
    label: "English",
    locale: "en",
    localeCulture: "en-GB",
  },
  de: {
    label: "Deutsch",
    locale: "de",
    localeCulture: "de",
  },
} as const;

export const DEFAULT_APP_LANGUAGE = APP_LANGUAGE.de;

export const DEFAULT_TABLE_PAGE_SIZE = 10;

export const acceptedDocumentTypes: readonly {
  type: string;
  label: string;
}[] = [
  { type: "application/pdf", label: ".pdf" },
  { type: "image/jpg", label: ".jpg" },
  { type: "image/jpeg", label: ".jpeg" },
  { type: "image/png", label: ".png" },
];

export const acceptedDocumentTypesString = acceptedDocumentTypes
  .map((type) => type.label)
  .join(", ");

const MAX_DOCUMENT_UPLOAD_SIZE_MB = 10;
export const BYTES_IN_KILOBYTE = 1024;
export const MAX_DOCUMENT_SIZE_BYTES =
  MAX_DOCUMENT_UPLOAD_SIZE_MB * BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE;
